<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline">Are you sure you want to enable the store?</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="ID" :readonly="formType === 'UPDATE'" v-model="form.id" data-cy="form-id"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Store No" v-model="form.storeNo" :readonly="formType === 'UPDATE'" data-cy="form-store-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Store Name" v-model="form.storeName" :readonly="formType === 'UPDATE'" data-cy="form-store-name"></v-text-field>
                            </v-flex>
                            
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Cancel</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
    },
    computed: {
        populatedForm() {
            return {
                id: this.selectedItem.id,
                storeNo: this.selectedItem.storeNo,
                storeName: this.selectedItem.storeName,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            form: {
                id: undefined,
                storeNo: undefined,
                storeName: undefined
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)                    
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('enable-store', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    id: this.selectedItem.id,
                    storeNo: this.selectedItem.storeNo,
                    storeName: this.selectedItem.storeName,
                }
            }
        }
    }
}
</script>
